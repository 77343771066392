import { roadMapItems } from "../../data/road-map-items";

import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useAos } from "../../utils/aos";

export function RoadMap() {
  const { t } = useTranslation();
  useAos();
  return (
    <section className="roadmap pt-[95px] lg:pt-[138px] pb-[30px] overflow-hidden">
      <div className="container">
        <h2
          data-aos="fade-up"
          className="text-center relative z-[3] mb-[-45px] lg:mb-[70px] lg:text-left font-extrabold text-[24px] relative z-[2] md:text-[30px] lg:text-[40px] leading-[125%]"
        >
          {t("roadmap.title")}
        </h2>
      </div>
      <dotlottie-player
        src="lottie/roadmap.lottie"
        autoplay
        loop
        class="relative lg:block hidden roadmap__image translate-x-[-50%]"
        style={{
          height: "100%",
          position: "relative",
          zIndex: "2",
          width: "1820px",
          left: "50%",
        }}
      />
      <div className="container roadmap__container">
        <div className="grid roadmap__content grid-cols-[1fr_0.7fr] max-w-[400px] lg:max-w-none mx-auto lg:block">
          <div className="roadmap__mobile-image relative">
            <dotlottie-player
              src="lottie/roadmap-mobile.lottie"
              autoplay
              loop
              class="block lg:hidden max-h-[580px] object-cover h-full mx-auto"
              style={{
                height: "100%",
                position: "relative",
                zIndex: "1",
                width: "100%",
              }}
            />
          </div>
          <div
            data-aos="fade-right"
            className="lg:grid roadmap__wrapper relative z-[4] mt-[116px] lg:mt-[39px] relative z-[2] lg:grid-cols-5 gap-[40px] lg:px-[25px] xl:px-[40px]"
          >
            {roadMapItems.map((item) => (
              <div className="roadmap__item-wrapper relative" key={item.id}>
                <div
                  className={`roadmap__item-arrow roadmap__item-arrow${item.id}`}
                >
                  <svg
                    width="58"
                    height="76"
                    viewBox="0 0 58 76"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_4868_7039)">
                      <path
                        d="M28.1016 75.9985C27.2032 74.7435 26.4019 73.3996 25.3821 72.2499C16.6084 62.3562 8.20707 52.1953 2.86518 39.9213C-3.86884 24.4412 1.51352 9.7869 16.4223 2.7593C30.4892 -3.87157 47.8018 1.85252 55.0134 15.4705C59.2626 23.4939 58.6718 31.5012 55.1429 39.557C49.6553 52.0658 41.1487 62.4695 32.1727 72.5494C31.242 73.5939 30.5297 74.8407 29.7122 75.9904H28.0935L28.1016 75.9985ZM41.8529 27.4206C41.8529 20.4254 36.0982 14.5637 29.1133 14.4666C21.9098 14.3613 15.969 20.2068 15.969 27.4044C15.969 34.3997 21.7318 40.2695 28.7086 40.3747C35.8635 40.48 41.861 34.5859 41.861 27.4287L41.8529 27.4206Z"
                        fill={item.color}
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_4868_7039"
                        x="-4"
                        y="-0.00292969"
                        width="65.998"
                        height="84.0015"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_4868_7039"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_4868_7039"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>
                <div className="roadmap__item rounded-[6px] relative text-[12px] lg:text-[14px] w-fit mx-auto lg:mx-0 xl:text-[16px] transition-all dark:bg-[#312D43] mb-[20px] bg-accentWhite px-[20px] py-[15px] lg:py-[27px] text-center">
                  <p>{t(`${item.text1}`)}</p>
                  <p>{t(`${item.text2}`)}</p>
                  <div className="absolute number flex items-center justify-center lg:top-[-15%] top-[50%] left-[-19%] translate-y-[-50%] lg:left-[50%] lg:translate-x-[-50%] w-[35px] h-[35px] lg:w-[50px] lg:h-[50px] rounded-[50%] font-black lg:text-[20px]">
                    <p>{t(`${item.number}`)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
