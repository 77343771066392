import { Suspense } from "react";
import { Footer } from "./components/footer";
import { FooterMobile } from "./components/footer/footer-mobile";
import { Header } from "./components/header";
import { Route, Routes } from "react-router-dom";
import { GpmLinkPage } from "./pages/gpmLinkPage";
import { HomePage } from "./pages/homePage";
import { DigitalTeam  } from "./pages/digitalTeam";


function App() {
  return (
    <>
      <Suspense>
        <div className="wrapper">
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="GPM-Link" element={<GpmLinkPage />} />
            <Route path="Digital-Team" element={<DigitalTeam />} />
          </Routes>
          <Footer />
          <FooterMobile />
        </div>
      </Suspense>
    </>
  );
}

export default App;
