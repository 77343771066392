import {footerLinks} from '../models'

export const footerLinks1: footerLinks[] = [
  {
    url: "#",
    text: "footer.link_list1.link1",
    id: 1,
  },
  {
    url: "#",
    text: "footer.link_list1.link2",
    id: 2
  },
  {
    url: "#",
    text: "footer.link_list1.link3",
    id: 3
  },
  {
    url: "#",
    text: "footer.link_list1.link4",
    id: 4
  },
  {
    url: "#",
    text: "footer.link_list1.link5",
    id: 5
  },
  {
    url: "#",
    text: "footer.link_list1.link6",
    id: 6
  }
]