import appStore from "../../media/images/app-store.svg";
import googleStore from "../../media/images/google-store.svg";
import { footerLinks1 } from "../../data/footer-links1";
import { footerLinks2 } from "../../data/footer-links2";
import { socialLinks } from "../../data/social-links";
import enFlag from "../../media/images/en-flag.svg";
import frFlag from "../../media/images/france-flag.svg";
import espFlag from "../../media/images/spain-flag.svg";
import logo from "../../media/images/desktop_logo.webp";

import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import i18n from "../../utils/i18next";
import { useAos } from "../../utils/aos";
import { Link } from "react-router-dom";
import { FooterLang } from "./footer-lang-switcher";

export function Footer() {
  const { t } = useTranslation();
  const [showLangs, setShowLangs] = useState(false);
  useAos();
  let langRef = useRef<any>();

  useEffect(() => {
    const node = langRef.current;
    let handler = (e: any) => {
      if (!node?.contains(e.target)) {
        setShowLangs(false);
      }
    };

    document.addEventListener("mousedown", handler);
  });
  return (
    <footer className="hidden md:block footer pt-[139px] pb-[98px]">
      <div className="container">
        <div
          data-aos="fade-right"
          className="grid grid-cols-4 gap-[40px] lg:gap-[78px]"
        >
          <div>
            <h3 className="text-[18px] lg:text-[24px] font-extrabold">
              {t("footer.language_title")}
            </h3>
            <div className="relative cursor-pointer" onClick={() => setShowLangs(!showLangs)} ref={langRef}>
              {i18n.languages[0] === "en" && (
                <div className="flex items-center mt-[20px]">
                  <img
                    src={enFlag}
                    alt="en"
                    className="max-w-[26px] max-h-[19px] mr-[12px]"
                  />
                  <p>English</p>
                </div>
              )}
              {i18n.languages[0] === "fr" && (
                <div className="flex items-center mt-[20px]">
                  <img
                    src={frFlag}
                    alt="fr"
                    className="max-w-[26px] max-h-[19px] mr-[12px]"
                  />
                  <p>Français</p>
                </div>
              )}
              {i18n.languages[0] === "esp" && (
                <div className="flex items-center mt-[20px]">
                  <img
                    src={espFlag}
                    alt="esp"
                    className="max-w-[26px] max-h-[19px] mr-[12px]"
                  />
                  <p>Español</p>
                </div>
              )}
              {showLangs && <FooterLang />}
            </div>
            <a
              href="https://ecologi.com/gpm?r=61ffd6b9276354d4cb608c1f"
              target="_blank"
              className="max-w-[200px] relative z-[1] hidden dark:block w-full mt-[48px] block"
              rel="noopener noreferrer"
              title="View our Ecologi profile"
            >
              <img
                alt="We offset our carbon footprint via Ecologi"
                src="https://api.ecologi.com/badges/cpw/61ffd6b9276354d4cb608c1f?white=true&landscape=true"
                className="w-full"
              />
            </a>
            <a
              href="https://ecologi.com/gpm?r=61ffd6b9276354d4cb608c1f"
              target="_blank"
              className="max-w-[200px] relative z-[1] dark:hidden w-full mt-[48px] block"
              rel="noopener noreferrer"
              title="View our Ecologi profile"
            >
              <img
                alt="We offset our carbon footprint via Ecologi"
                src="https://api.ecologi.com/badges/cpw/61ffd6b9276354d4cb608c1f?black=true&landscape=true"
                className="w-full"
              />
            </a>
          </div>
          <div>
            <h3 className="text-[18px] lg:text-[24px] font-extrabold">
              {t("footer.link_list_title")}
            </h3>
            <ul className="mt-[20px] flex flex-col gap-[12px]">
              {footerLinks1.map((link) => (
                <Link
                  to={link.url}
                  className="block transition-all hover:text-purple"
                  key={link.id}
                >
                  {" "}
                  {t(`${link.text}`)}
                </Link>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-[18px] lg:text-[24px] font-extrabold">
              {t("footer.link_list_title2")}
            </h3>
            <ul className="mt-[20px] flex flex-col gap-[12px]">
              {footerLinks2.map((link) => (
                <Link
                  to={link.url}
                  className="block transition-all hover:text-purple"
                  key={link.id}
                >
                  {t(`${link.text}`)}
                </Link>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-[18px] lg:text-[24px] font-extrabold">
              {t("footer.link_list_title3")}
            </h3>
            <div className="mt-[20px] social-links grid grid-cols-5 gap-[13px]">
              <a href="/">
                <img src={logo} alt="gpm_logo" />
              </a>
              {socialLinks.map((link) => (
                <a href={link.url} key={link.id}>
                  {link.image}
                </a>
              ))}
            </div>
            <div className="mt-[67px]">
              <h3 className="text-[24px] mb-[20px] font-extrabold">
                {t("footer.app")}
              </h3>
              <a href="/">
                <img src={appStore} alt="logo" />
              </a>
              <a href="/">
                <img src={googleStore} className="ml-[-5px]" alt="logo" />
              </a>
            </div>
          </div>
        </div>
        <p data-aos="fade-left" className="mt-[125px] text-center text-[12px]">
          {t("footer.copyright")}
        </p>
      </div>
    </footer>
  );
}
