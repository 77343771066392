import heroDigital from "../../media/images/hero-digital.png";
import { Button } from "../button";
import { Typewriter } from "react-simple-typewriter";

import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { heroSectionProps } from "../../models";

export function HeroSectionTeam(props: heroSectionProps) {
  const { t } = useTranslation();
  return (
    <section className="hero-section h-full mt-[128px] md:mt-[167px] relative pb-[88px] md:pb-[149px]">
      <div className="hidden md:block gradient-decor absolute max-w-[413px] top-[-40%] left-[-8%] rounded-[100%] bg-white max-h-[413px] w-full h-full"></div>
      <div className="container h-full">
        <div className="grid md:grid-cols-2 md:gap-[36px] relative z-[2]">
          <div className="text-center md:text-left">
            <h1 className="relative z-[2] mx-auto md:mx-0 leading-[111%] text-[28px] lg:text-[35px] xl:text-[48px] font-black max-w-[652px]">
              {props.title1} <br className="hidden md:block"></br>
              <b className="gradient-text">
                <Typewriter
                  words={[
                    `${t("hero-section.dynamic_title1")}`,
                    `${t("hero-section.dynamic_title2")}`,
                  ]}
                  loop={false}
                  typeSpeed={100}
                  deleteSpeed={100}
                  delaySpeed={1000}
                />
              </b>{" "}
              {props.title2}
              {props.title3}
            </h1>
            <p className="font-bold relative z-[2] md:font-medium lg:text-[24px] md:mb-0 mt-[23px]">
              {props.text}
            </p>
            <Button
              buttonClass="hidden max-w-[240px] text-[16px] md:text-[20px] button mt-[67px] relative z-[3] transition-all md:block w-full text-center"
              buttonUrl="#"
              buttonText={props.button}
            />
          </div>
          <div className="hero-section__right md:relative mt-[32px] md:mt-[30px] md:mt-0">
            <div className="gradient-decor hero-section-decor z-[1] absolute max-w-[350px] max-h-[350px] md:max-w-[250px] md:max-h-[250px] md:top-[50%] md:left-[50%] left-[69%] top-[33%] xl:max-w-[460px] opacity-80 rounded-[100%] bg-white xl:max-h-[460px] w-full h-full"></div>
            <div className="hero-section__image-wrapper">
              <img
                  className="hero-section__image-mobile max-w-[400px] md:max-w-none mx-auto relative relative z-[2] w-full mx-auto min-h-[1px]"
                  src={heroDigital}
                  alt="hero_collage"
                />
            </div>
          </div>
        </div>
        <Button
          buttonClass="md:hidden button mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[38px] lg:mt-[40px] max-w-[190px] md:max-w-[247px] w-full"
          buttonUrl="#"
          buttonText={props.button}
        />
      </div>
    </section>
  );
}
