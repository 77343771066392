import {plans} from '../models'

export const digitalPlansDataFree: plans[] = [
  {
    image: <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.66667L7.2 10L15 2" stroke="#5ED3D0" strokeWidth="3"/></svg>,
    text: "digital_team.plans.free.item1",
    id: 1
  },
  {
    image: <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.66667L7.2 10L15 2" stroke="#5ED3D0" strokeWidth="3"/></svg>,
    text: "digital_team.plans.free.item2",
    id: 2
  },
  {
    image: <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.3257 12.8437L7.02568 8.54363L2.69997 12.8693L0.715345 10.8847L5.04105 6.55901L0.741021 2.25898L2.65013 0.349869L6.95016 4.6499L11.3 0.300027L13.2847 2.28465L8.93479 6.63452L13.2348 10.9346L11.3257 12.8437Z" fill="white" fillOpacity="0.3"/></svg>,
    text: "digital_team.plans.free.item3",
    id: 3,
    className: "disabled"
  },
  {
    image: <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.3257 12.8437L7.02568 8.54363L2.69997 12.8693L0.715345 10.8847L5.04105 6.55901L0.741021 2.25898L2.65013 0.349869L6.95016 4.6499L11.3 0.300027L13.2847 2.28465L8.93479 6.63452L13.2348 10.9346L11.3257 12.8437Z" fill="white" fillOpacity="0.3"/></svg>,
    text: "digital_team.plans.free.item4",
    id: 4,
    className: "disabled"
  },
  {
    image: <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.3257 12.8437L7.02568 8.54363L2.69997 12.8693L0.715345 10.8847L5.04105 6.55901L0.741021 2.25898L2.65013 0.349869L6.95016 4.6499L11.3 0.300027L13.2847 2.28465L8.93479 6.63452L13.2348 10.9346L11.3257 12.8437Z" fill="white" fillOpacity="0.3"/></svg>,
    text: "digital_team.plans.free.item5",
    id: 5,
    className: "disabled"
  },
  {
    image: <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.3257 12.8437L7.02568 8.54363L2.69997 12.8693L0.715345 10.8847L5.04105 6.55901L0.741021 2.25898L2.65013 0.349869L6.95016 4.6499L11.3 0.300027L13.2847 2.28465L8.93479 6.63452L13.2348 10.9346L11.3257 12.8437Z" fill="white" fillOpacity="0.3"/></svg>,
    text: "digital_team.plans.free.item6",
    id: 6,
    className: "disabled"
  },
  {
    image: <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.3257 12.8437L7.02568 8.54363L2.69997 12.8693L0.715345 10.8847L5.04105 6.55901L0.741021 2.25898L2.65013 0.349869L6.95016 4.6499L11.3 0.300027L13.2847 2.28465L8.93479 6.63452L13.2348 10.9346L11.3257 12.8437Z" fill="white" fillOpacity="0.3"/></svg>,
    text: "digital_team.plans.free.item7",
    id: 7,
    className: "disabled"
  },
  {
    image: <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.3257 12.8437L7.02568 8.54363L2.69997 12.8693L0.715345 10.8847L5.04105 6.55901L0.741021 2.25898L2.65013 0.349869L6.95016 4.6499L11.3 0.300027L13.2847 2.28465L8.93479 6.63452L13.2348 10.9346L11.3257 12.8437Z" fill="white" fillOpacity="0.3"/></svg>,
    text: "digital_team.plans.free.item8",
    id: 8,
    className: "disabled"
  }
]