import {plans} from '../models'

export const digitalPlansDataPremium: plans[] = [
  {
    image: <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.66667L7.2 10L15 2" stroke="#5ED3D0" strokeWidth="3"/></svg>,
    text: "digital_team.plans.premium.item1",
    id: 1
  },
  {
    image: <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.66667L7.2 10L15 2" stroke="#5ED3D0" strokeWidth="3"/></svg>,
    text: "digital_team.plans.premium.item2",
    id: 2
  },
  {
    image: <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.66667L7.2 10L15 2" stroke="#5ED3D0" strokeWidth="3"/></svg>,
    text: "digital_team.plans.premium.item3",
    id: 3
  },
  {
    image: <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.66667L7.2 10L15 2" stroke="#5ED3D0" strokeWidth="3"/></svg>,
    text: "digital_team.plans.premium.item4",
    id: 4
  },
  {
    image: <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.66667L7.2 10L15 2" stroke="#5ED3D0" strokeWidth="3"/></svg>,
    text: "digital_team.plans.premium.item5",
    id: 5,
  },
  {
    image: <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.66667L7.2 10L15 2" stroke="#5ED3D0" strokeWidth="3"/></svg>,
    text: "digital_team.plans.premium.item6",
    id: 6
  },
  {
    image: <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.66667L7.2 10L15 2" stroke="#5ED3D0" strokeWidth="3"/></svg>,
    text: "digital_team.plans.premium.item7",
    id: 7
  },
  {
    image: <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.66667L7.2 10L15 2" stroke="#5ED3D0" strokeWidth="3"/></svg>,
    text: "digital_team.plans.premium.item8",
    id: 8
  }
]