import ContactUs from "../components/sections/contact-form";
import { LocationSwiper } from "../components/sections/location-swiper";
import { FaqSection } from "../components/sections/faq-section";
import { HeroSection } from "../components/sections/hero-section";
import { ImageLeft } from "../components/sections/image-left";
import { ImageRight } from "../components/sections/image-right";
import { ImageRightNoDecor } from "../components/sections/image-right-no-decor";
import { NewsForm } from "../components/sections/newsletter";
import { RoadMap } from "../components/sections/road-map";
import { ThreeBackground } from "../components/sections/tree-background-section";
import { DiscountSwiper } from "../components/sections/discont-swiper";

import { useTranslation } from "react-i18next";
import "../utils/i18next";
import { BuildProfile } from "../components/sections/profile-build-section";

export function HomePage() {
  const { t } = useTranslation();
  return (
    <main className="main">
      <HeroSection
        button={`${t("hero-section.button")}`}
        text={`${t("hero-section.subtitle")}`}
        title1={`${t("hero-section.title1")}`}
        title2={`${t("hero-section.title3")}`}
      />
      <BuildProfile />
      <ThreeBackground />
      <ImageRight />
      <DiscountSwiper />
      <LocationSwiper />
      <ImageLeft />
      <ImageRightNoDecor />
      <RoadMap />
      <FaqSection />
      <ContactUs />
      <NewsForm />
    </main>
  );
}
