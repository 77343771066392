import { Button } from "../button";
import "@dotlottie/player-component";

import { useAos } from "../../utils/aos";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";

export function ImageRight(){
  const { t } = useTranslation();
  useAos();
  return(
    <section className="image-right relative overflow-hidden pt-[82px] pb-[55px] md:py-[126px]">
      <div className="gradient-decor absolute max-w-[220px] max-h-[220px] lg:max-w-[494px] w-full h-full lg:max-h-[494px] z-[1] right-0 lg:right-[-10%] top-[-14%] lg:top-[-50%]"></div>
      <div className="container">
        <div className="md:grid relative z-[2] grid-cols-2 gap-[29px] items-center">
          <div data-aos="fade-up">
            <h2 className="font-extrabold mb-[23px] md:mb-[0] text-center md:text-left text-[24px] md:text-[30px] lg:text-[40px] leading-[125%]">{t("image-right.title")}</h2>
            <div data-aos="fade-left">
              <dotlottie-player
                src="lottie/dashboard.lottie"
                autoplay
                loop
                class="md:hidden"
                loading="lazy"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <p className="mt-[10px] lg:mt-[35px] text-center md:text-left text-[14px] md:text-[16px] lg:text-[18px] leading-[167%]">{t("image-right.text")}</p>
            <Button buttonClass="button mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[30px] lg:mt-[40px] max-w-[190px] md:max-w-[247px] w-full" buttonText={t("image-right.button")} buttonUrl="#" />
          </div>
          <div data-aos="fade-left">
            <dotlottie-player
              src="lottie/dashboard.lottie"
              autoplay
              class="hidden md:block"
              loop
              loading="lazy"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="gradient-decor absolute max-w-[220px] w-full h-full max-h-[220px] z-[1] block md:hidden bottom-[-14%] left-[-14%]"></div>
    </section>
  )
}