import {features} from '../models'

export const featuresDataDigital: features[] = [
  {
    image: "images/features1.svg",
    title: "features.item1.title",
    text: "features.item1.text",    
    id: 1,
  },
  {
    image: "images/features-digital.svg",
    title: "features.item2.title",    
    text: "features.item2.text",    
    id: 2
  },
  {
    image: "images/features3.svg",
    title: "features.item3.title",
    text: "features.item3.text",    
    id: 3
  },
]
