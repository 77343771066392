import ContactUs from "../components/sections/contact-form";
import { FaqSection } from "../components/sections/faq-section";
import { HeroSectionTeam } from "../components/sections/hero-section-team";
import { NewsForm } from "../components/sections/newsletter";
import { ThreeBackground } from "../components/sections/tree-background-section";

import { useTranslation } from "react-i18next";
import "../utils/i18next";
import { Features } from "../components/sections/features";
import { GpmLinkRightImage } from "../components/sections/gpm-link-right-image";
import { GpmLinkLefttImage } from "../components/sections/gpm-link-left-image";
import { DigitalImageLeft } from "../components/sections/digital-image-left";
import { DigitalImageRight } from "../components/sections/digital-image-right";
import { DigitalGrid } from "../components/sections/digital-grid";
import { DigitalPlans } from "../components/sections/digital-plans";

import {featuresDataDigital} from "../data/featuresDigital"


export function DigitalTeam() {
  const { t } = useTranslation();
  return (
    <main className="main digital-team-page">
      <HeroSectionTeam
        button={`${t("digital_team.hero-section.button")}`}
        text={`${t("digital_team.hero-section.text")}`}
        title1={`${t("digital_team.hero-section.title1")}`}
        title2={`${t("digital_team.hero-section.title2")}`}
      />
      <Features data={featuresDataDigital} />
      <DigitalImageLeft />
      <DigitalImageRight />
      <GpmLinkLefttImage
        title={t("digital_team.image-left-phone.title")}
        text={t("digital_team.image-left-phone.text")}
        button={t("digital_team.image-left-phone.button")}
      />
      <GpmLinkRightImage
        title={t("digital_team.image-right-phone.title")}
        text={t("digital_team.image-right-phone.text")}
        button={t("digital_team.image-right-phone.button")}
      />
      <ThreeBackground />
      <DigitalGrid />
      <DigitalPlans />
      <FaqSection />
      <ContactUs />
      <NewsForm />
    </main>
  );
}
