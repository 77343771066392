import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useAos } from "../../utils/aos";

import {featuresProps} from "../../models"

export function Features(props :featuresProps) {
  const { t } = useTranslation();
  useAos();
  return (
    <section className="features relative mt-[32px] mb-[32px]">
      <div className="lg:hidden gradient-decor absolute z-[1] right-[-10%] top-[20%] max-w-[215px] max-h-[215px] w-full h-full"></div>
      <div className="container">
        <div data-aos="fade-up" className="features__content relative z-[2] max-w-[300px] md:max-w-none mx-auto py-[37px] md:py-[63px] px-[47px] md:px-[50px] lg:px-[81px]">
          <h2 className="md:block lg:hidden text-center mb-[29px] text-[25px] md:text-[40px] leading-[135%] font-extrabold">
            {t("features.title")}
          </h2>
          <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 items-center gap-[32px]">
            <h2 className="hidden lg:block text-[40px] leading-[135%] font-extrabold">
              {t("features.title")}
            </h2>
            {props.data.map((item: any) => (
              <div
                key={item.id}
                className="h-full w-full md:max-w-none mx-auto flex flex-col dark:bg-[#312D43] text-center bg-white/80 rounded-[20px] pt-[16px] px-[10px] py-[19px] md:px-[19px] md:pb-[23px]"
              >
                <div className="features__image-wrapper relative mb-[12px]">
                  <img
                    src={item.image}
                    alt="features"
                    className="max-w-[85px] relative z-[2] w-full mx-auto"
                  />
                </div>
                <h3 className="mt-auto font-extrabold text-[20px]">
                  {t(`${item.title}`)}
                </h3>
                <p className="text-[12px] mt-[13px] md:max-w-[163px] mx-auto">
                  {t(`${item.text}`)}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="lg:hidden gradient-decor absolute z-[1] left-[-10%] top-[40%] max-w-[215px] max-h-[215px] w-full h-full"></div>
        <div className="lg:hidden gradient-decor absolute z-[1] right-[-10%] bottom-[-10%] max-w-[215px] max-h-[215px] w-full h-full"></div>

      </div>
    </section>
  );
}
