import { Button } from "../button";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useAos } from "../../utils/aos";
import { plansDataFree } from "../../data/plans-free";
import { plansDataPremium } from "../../data/plans-premium";

export function Plans() {
  const { t } = useTranslation();
  useAos();
  return (
    <section className="plans relative overflow-y-hidden md:pt-[122px]">
      <div className="container">
        <h2 className="text-center max-w-[499px] mx-auto font-extrabold leading-[125%] text-[24px] relative z-[2] md:text-[30px] lg:text-[40px] ">{t("plans.title")}</h2>
        <div className="md:grid-cols-2 relative grid gap-[53px] mt-[59px] md:mt-[72px]">
          <div data-aos="fade-right" className="z-[2] relative h-fit w-fit md:w-full md:h-full md:min-h-[461px] transition-all z-[2] px-[25px] md:px-[41px] pt-[46px] md:pt-[55px] pb-[24px] md:pb-[14px] plans__item plans__item-free md:max-w-[400px] rounded-[12px] bg-white/80 dark:bg-[#312D43] mx-auto md:mr-0">
            <h3 className="absolute font-extrabold text-[16px] py-[9px] md:py-[13px] leading-[125%] max-w-[100px] md:max-w-[128px] min-w-[100px] md:min-w-[128px] text-center type-free left-[50%] translate-x-[-50%] top-0 translate-y-[-50%]">{t("plans.free.type")}</h3>
            <div>
              {plansDataFree.map((item) => (
                <div key={item.id} className={`flex mb-[9px] md:mb-[12px] ${item.className}`}>
                  {item.image}                  
                  <p className={`max-w-[244px] text-[12px] md:text-[16px] leading-[125%]`}>{t(`${item.text}`)}</p>
                </div>
              ))}
              <p className="text-center font-black text-[24px] md:text-[36px]">{t("plans.free.price")}</p>
              <p className="text-center hidden md:block opacity-0 text-[14px]">{t("plans.premium.subtitle")}</p>
            </div>
          </div>
          <div data-aos="fade-left">
            <div className="relative h-fit w-fit md:w-full md:h-full md:min-h-[461px] md:max-w-[400px] px-[25px] md:px-[41px] pt-[46px] md:pt-[55px] pb-[6px] z-[2] md:pb-[14px] transition-all plans__item rounded-[12px] bg-white/80 dark:bg-[#312D43] mx-auto md:ml-0">
              <h3 className="absolute text-[16px] leading-[125%] py-[9px] md:py-[13px] font-extrabold max-w-[100px] md:max-w-[128px] text-center min-w-[100px] md:min-w-[128px] type-premium left-[50%] translate-x-[-50%] top-0 translate-y-[-50%]">{t("plans.premium.type")}</h3>
              <div>
                {plansDataPremium.map((item) => (
                  <div key={item.id} className="flex mb-[12px]">
                    {item.image}                  
                    <p className={`max-w-[244px] text-[12px] md:text-[16px] leading-[125%]`}>{t(`${item.text}`)}</p>
                  </div>
                ))}
                <h2 className="font-black text-[24px] md:text-[36px] flex items-center justify-center text-center">
                  {t("plans.premium.price")}
                  <p className="mounth-bottom text-[11px] md:text-[14px] font-semibold">{t("plans.premium.mo")}</p>
                </h2>
                <p className="text-center text-[9px] md:text-[14px]">{t("plans.premium.subtitle")}</p>
              </div>
              <p className="absolute bottom-[-20px] md:bottom-[-30px] whitespace-nowrap text-[9px] md:text-[14px] left-[50%] translate-x-[-50%]">{t("plans.premium.bottom_info")}</p>
            </div>
          </div>
          <div className="gradient-decor gradient-decor__plans z-[1] absolute w-full h-full max-w-[300px] max-h-[300px] left-[50%] top-[50%]"></div>
        </div>
        <Button
          buttonClass="button mx-auto relative z-[3] transition-all block text-center text-[16px] md:text-[20px] mt-[48px] md:mt-[68px] max-w-[190px] md:max-w-[247px] w-full"
          buttonText={t("plans.button")}
          buttonUrl="#"
        />
      </div>
    </section>
  );
}
