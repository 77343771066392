import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useAos } from "../../utils/aos";

export default function ContactUs() {
  const { t } = useTranslation(); 
  useAos();

  function sendEmail(e: any) {
    e.preventDefault();

    emailjs.sendForm("service_njugrsn", "template_yfaphk7", e.target, "WZXMN3LesK8A346Ss")
    .then(
      (result) => {
      },
      (error) => {
      }
    );
  }

  return (
    <section className="contact-us pt-[112px] md:pt-[219px] pb-[75px] md:pb-[125px]">
      <div className="container">
        <h2 data-aos="fade-up" className="text-center font-extrabold text-[24px] relative z-[2] md:text-[30px] lg:text-[40px] leading-[125%]">{t("contact-us.title")}</h2>
        <div className="max-w-[680px] relative mx-auto mt-[45px] md:mt-[60px]">
          <div className="gradient-decor w-full h-full max-w-[211px] max-h-[211px] md:max-w-[300px] top-[-50%] md:top-[-10%] left-[-10%] md:max-h-[300px] absolute z-[1]"></div>
          <form className="contact-form relative z-[2]" onSubmit={sendEmail}>
            <div data-aos="fade-right" className="mb-[14px] md:mb-[24px] input__wrapper">
              <input required className="w-full sm:px-[33px] py-[11px] px-[19px] rounded-[6px] text-[12px] text-accentDark placeholder:accentDark placeholder:text-[12px] sm:text-[16px] sm:placeholder:text-[16px] dark:placeholder:text-accentWhite sm:py-[19px] input transition-all dark:text-accentWhite dark:bg-[#312D43]" type="text" placeholder={t("contact-us.form.name")} name="from_name" />
            </div>
            <div data-aos="fade-left" className="mb-[14px] md:mb-[24px] input__wrapper">
              <input required className="w-full sm:px-[33px] py-[11px] px-[19px] rounded-[6px] text-[12px] text-accentDark placeholder:accentDark placeholder:text-[12px] sm:text-[16px] sm:placeholder:text-[16px] dark:placeholder:text-accentWhite sm:py-[19px] transition-all input dark:text-accentWhite dark:bg-[#312D43]" type="email" placeholder={t("contact-us.form.email")} name="from_email" />
            </div>
            <div data-aos="fade-right" className="input__wrapper">
              <textarea className="text-accentDark dark:text-accentWhite resize-none py-[11px] px-[19px] min-h-[170px] sm:min-h-[271px] text-accentDark placeholder:accentDark text-[12px] placeholder:text-[12px] sm:text-[16px] sm:placeholder:text-[16px] rounded-[6px] w-full sm:px-[33px] transition-all dark:placeholder:text-accentWhite sm:py-[19px] input dark:bg-[#312D43]" placeholder={t("contact-us.form.message")} name="html_message" />
            </div>
            <div data-aos="fade-left">
              <button className="button send-button mx-auto transition-all block text-center text-[16px] md:text-[20px] mt-[43px] lg:mt-[54px] max-w-[190px] md:max-w-[247px] w-full" type="submit">
                {t("contact-us.form.button")}
              </button>
            </div>
          </form>
          <div className="hidden md:block gradient-decor w-full h-full max-w-[300px] max-h-[300px] bottom-[-10%] right-[-10%] absolute z-[1]"></div>
        </div>
      </div>
    </section>
  );
}
