import { ProductLinksLocal } from "../../data/product-menu";
import { Button } from "../button";
import enFlag from "../../media/images/en-flag.svg";
import frFlag from "../../media/images/france-flag.svg";
import espFlag from "../../media/images/spain-flag.svg";

import { useLangSwitcher } from "../../hooks/langSwitcher";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { Link } from "react-router-dom";
import { menuProps } from "../../models";

export function MobileMenu(props: menuProps) {
  const { t } = useTranslation();
  const { changeLanguage, langState } = useLangSwitcher();
  return (
    <div className="mobile__menu h-[80vh] overflow-y-auto">
      <Link
        to="/"
        onClick={props.click}
        className="gradient-bg font-semibold mt-[2px] mx-[2px] mb-2.5	submenu__link relative z-[10] px-[19px] py-[15px] dark:bg-[#242331] transition rounded-[6px] flex justify-between items-center"
      >
        {t("header.home_link")}
      </Link>
      <details className="outline-0 relative z-[2] mobile__menu-details">
        <summary className="gradient-bg font-semibold mx-[2px] submenu__link relative z-[10] px-[19px] py-[15px] dark:bg-[#242331] transition rounded-[6px] flex justify-between items-center">
          <p>{t("header.mobile_menu_title")}</p>
          <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 8L7 2L13 8" stroke="#3C3C40" strokeWidth="2" />
          </svg>
        </summary>
        <div className="relative z-[2]">
          {ProductLinksLocal.map((link) => (
            <Link
              to={link.url}
              key={link.id}
              onClick={props.click}
              className="gradient-bg relative z-[2] submenu__link mx-[2px] font-medium mt-2.5 px-[19px] py-[8px] min-h-[54px] dark:bg-[#242331] hover:text-purple relative z-[10] transition flex rounded-[6px] items-center"
            >
              <img
                src={link.image}
                className="mr-[17px] min-h-[23px] min-w-[17px]"
                alt="prorudct_ico"
              />
              {t(`${link.text}`)}
            </Link>
          ))}
        </div>
      </details>
      <Link
        to="/about"
        onClick={props.click}
        className="relative z-[2] gradient-bg font-semibold mt-2.5 mx-[2px] submenu__link relative z-[10] px-[19px] py-[15px] dark:bg-[#242331] transition rounded-[6px] flex justify-between items-center"
      >
        {t("header.contact_link")}
      </Link>
      <div className="relative z-[2] mobile__menu-buttons flex justify-center items-center mt-[38px]">
        <Button
          buttonClass="block mr-[15px] text-center lg:hidden button button-bordered transition-all"
          buttonUrl="#"
          buttonText={t("header.button_bordered")}
        />
        <Button
          buttonClass="block text-center lg:hidden button transition-all"
          buttonUrl="#"
          buttonText={t("header.button")}
        />
      </div>
      <div className="relative z-[2] flex justify-center gap-x-6	mt-[50px] pb-14">
        <button className="w-[38px] block" onClick={() => changeLanguage("en")}>
          <img
            className={`w-[38px] transition-all h-[34px] ${
              langState === "en" ? "current-lang" : ""
            }`}
            src={enFlag}
            alt="en"
          />
        </button>
        <button className="w-[38px] block" onClick={() => changeLanguage("fr")}>
          <img
            className={`w-[38px] transition-all h-[34px] ${
              langState === "fr" ? "current-lang" : ""
            }`}
            src={frFlag}
            alt="fr"
          />
        </button>
        <button
          className="w-[38px] block"
          onClick={() => changeLanguage("esp")}
        >
          <img
            className={`w-[38px] transition-all h-[34px] ${
              langState === "esp" ? "current-lang" : ""
            }`}
            src={espFlag}
            alt="esp"
          />
        </button>
      </div>
    </div>
  );
}
