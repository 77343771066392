import {productLinks} from '../models'

export const ProductLinksLocal: productLinks[] = [
  {
    image: "images/product1.svg",
    url: "/GPM-Link",
    text: "product_links.product1",
    id: 1
  },
  {
    image: "images/product2.svg",
    url: "/Digital-Team",
    text: "product_links.product2",
    id: 2
  },
  {
    image: "images/product3.svg",
    url: "/",
    text: "product_links.product3",
    id: 3
  },
  {
    image: "images/product4.svg",
    url: "/",
    text: "product_links.product4",
    id: 4
  },
  {
    image: "images/product5.svg",
    url: "/",
    text: "product_links.product5",
    id: 5
  },
  {
    image: "images/product6.svg",
    url: "/",
    text: "product_links.product6",
    id: 6
  }
]