import {footerLinks} from '../models'

export const footerLinks2: footerLinks[] = [
  {
    url: "#",
    text: "footer.link_list2.link1",
    id: 1,
  },
  {
    url: "#",
    text: "footer.link_list2.link2",
    id: 2
  },
  {
    url: "#",
    text: "footer.link_list2.link3",
    id: 3
  },
  {
    url: "#",
    text: "footer.link_list2.link4",
    id: 4
  }
]